body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.privacyWarning {
    background-color: #f0f8ff;
    border: 1px solid #4189c7;
    border-radius: 3px;
    bottom: 5px;
    left: 50%;
    padding: 5px;
    position: fixed;
    width: 50%;
    z-index: 10;
    text-align:left;
}

.projectTech{
    margin-right: 5px;
}

#root{
    margin-bottom:30px;
}

footer {
    left: 0;
    position: fixed;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #dcdcdc;
    bottom: 0;
    padding: 5px;
    z-index: 5;
}

/*#myNavbar{
    max-height:20px!important;
    height:20px!important;
}*/